<template>
	<div class="leads">
		<div class="leads-list" v-if="sortedLeads.length">
			<div
					v-for="lead in sortedLeads"
					:key="lead.leadID"
					class="leads-list__item"
					:class="{
            'leads-list__item--active': selectedLead && selectedLead.leadID === lead.leadID,
          }"
					@click="selectedLead = lead"
			>
				<div class="practice-name">{{ lead.practiceName }}</div>
				<div class="start-time">({{ lead.leadStarted }})</div>
				<hr>
			</div>
		</div>
		<div class="leads-details" v-if="selectedLead">
			<div class="tools">
				<v-btn @click="downloadPDF" class="btn">
					Download PDF
				</v-btn>
				<v-btn @click="createNewCompany" class="btn" v-if="!companies[selectedLead.leadID]">
					Create New Company
				</v-btn>
				<v-btn @click="deleteModal = !deleteModal"
						class="btn"
						outlined
						color="red"
				>
					Delete
				</v-btn>
			</div>
			<div class="scrolling" ref="leadsDetails">
				<div class="pdf-form">
					<div class="header pdf-row">
						<div class="pdf-logo col-3"><img src="@/assets/biolabsciences.png" class="logo-img" alt="biolab logo"/>
						</div>
						<div class="pdf-title col-6">New Account Registration</div>
						<div class="pdf-logo2 col-3"><img src="@/assets/myownskin.png" class="logo-img" alt="my own skin logo"/>
						</div>
					</div>

					<div class="pdf-header"><span class="left">Sales Representation Information</span><span></span></div>

					<div class="pdf-row  a">
						<div class="pdf-col-name">Sales Rep Name:</div>
						<div class="pdf-col-value">EMS Bio</div>
						<div class="pdf-col-name">Sales Rep Address:</div>
						<div class="pdf-col-value">1333 N 430 E Orem, UT 84097</div>
					</div>

					<div class="pdf-row  a">
						<div class="pdf-col-name">Sales Rep Email:</div>
						<div class="pdf-col-value">brent@emsbio.com</div>
						<div class="pdf-col-name">Sales Rep Work Phone #:</div>
						<div class="pdf-col-value">801-376-0227</div>
					</div>

					<div class="pdf-row  a">
						<div class="pdf-col-name">Distribution Group:</div>
						<div class="pdf-col-value">EMS Bio</div>
						<div class="pdf-col-name">Sales Rep Mobile Phone #:</div>
						<div class="pdf-col-value">801-376-0227</div>
					</div>

					<div class="pdf-header"><span class="left">Facility/Practice Information</span> <span class="right">Office Hours</span>
					</div>

					<div class="pdf-row">
						<div class="section2__left">
							<div class="pdf-row b">
								<div class="pdf-col-name">Facility/Practice Name:</div>
								<div class="pdf-col-value">{{ selectedLead.practiceName }}</div>
							</div>

							<div class="pdf-row b">
								<div class="pdf-col-name">Street Address:</div>
								<div class="pdf-col-value">{{ selectedLead.practiceStreet }}</div>

							</div>

							<div class="pdf-row b">
								<div class="pdf-col-name">City/State/Zip:</div>
								<div class="pdf-col-value">{{ selectedLead.practiceCity }},
									{{ selectedLead.state || selectedLead.practiceState }}, {{ selectedLead.practiceZip }}
								</div>
							</div>

							<div class="pdf-row b">
								<div class="pdf-col-name">Telephone #:</div>
								<div class="pdf-col-value">{{ selectedLead.practicePhone }}</div>
							</div>

							<div class="pdf-row b">
								<div class="pdf-col-name">Fax #:</div>
								<div class="pdf-col-value"></div>
							</div>

							<div class="pdf-row b">
								<div class="pdf-col-name big">Practice Contact:</div>
								<div class="big">
									<div class="split">
										<div class="pdf-col-name">Name:</div>
										<div class="pdf-col-value">{{ selectedLead.practiceContactName }}</div>
									</div>
									<div class="split">
										<div class="pdf-col-name">Email:</div>
										<div class="pdf-col-value">{{ selectedLead.practiceContactEmail }}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="section2__right">
							<div class="pdf-row">
								<div class="pdf-col-name">Monday:</div>
								<div class="pdf-col-value" v-if="selectedLead.businessHours.mon.open">
									{{ selectedLead.businessHours.mon.start }}/{{ selectedLead.businessHours.mon.end }}
								</div>
								<div class="pdf-col-value" v-else>Closed</div>
							</div>
							<div class="pdf-row">
								<div class="pdf-col-name">Tuesday:</div>
								<div class="pdf-col-value" v-if="selectedLead.businessHours.tue.open">
									{{ selectedLead.businessHours.tue.start }}/{{ selectedLead.businessHours.tue.end }}
								</div>
								<div class="pdf-col-value" v-else>Closed</div>
							</div>
							<div class="pdf-row">
								<div class="pdf-col-name">Wednesday:</div>
								<div class="pdf-col-value" v-if="selectedLead.businessHours.wed.open">
									{{ selectedLead.businessHours.wed.start }}/{{ selectedLead.businessHours.wed.end }}
								</div>
								<div class="pdf-col-value" v-else>Closed</div>
							</div>
							<div class="pdf-row">
								<div class="pdf-col-name">Thursday:</div>
								<div class="pdf-col-value" v-if="selectedLead.businessHours.thu.open">
									{{ selectedLead.businessHours.thu.start }}/{{ selectedLead.businessHours.thu.end }}
								</div>
								<div class="pdf-col-value" v-else>Closed</div>
							</div>
							<div class="pdf-row">
								<div class="pdf-col-name">Friday:</div>
								<div class="pdf-col-value" v-if="selectedLead.businessHours.fri.open">
									{{ selectedLead.businessHours.fri.start }}/{{ selectedLead.businessHours.fri.end }}
								</div>
								<div class="pdf-col-value" v-else>Closed</div>
							</div>
							<div class="pdf-row">
								<div class="pdf-col-name">Saturday:</div>
								<div class="pdf-col-value" v-if="selectedLead.businessHours.sat.open">
									{{ selectedLead.businessHours.sat.start }}/{{ selectedLead.businessHours.sat.end }}
								</div>
								<div class="pdf-col-value" v-else>Closed</div>
							</div>
							<div class="pdf-row">
								<div class="pdf-col-name">Sunday:</div>
								<div class="pdf-col-value" v-if="selectedLead.businessHours.sun.open">
									{{ selectedLead.businessHours.sun.start }}/{{ selectedLead.businessHours.sun.end }}
								</div>
								<div class="pdf-col-value" v-else>Closed</div>
							</div>
						</div>
					</div>

					<div class="pdf-header"><span class="left">Practice Providers and/or Facility Information</span> <span
							class="right"></span></div>

					<div class="section-c">
						<div class="pdf-row">
							<div class="pdf-col-name aa">Provider Name, Credentials</div>
							<div class="pdf-col-name bb">NPI #</div>
							<div class="pdf-col-name cc">License #</div>
						</div>
						<template v-if="selectedLead.providers && selectedLead.providers.length && selectedLead.providers[0].name">
							<div class="pdf-row" v-for="(provider, i) in selectedLead.providers" :key="i">
								<div class="pdf-col-value aa">{{ provider.name }}</div>
								<div class="pdf-col-value bb">{{ provider.npi }}</div>
								<div class="pdf-col-value cc">{{ provider.license }}</div>
							</div>
						</template>
						<template v-else>
							<div class="pdf-row">
								<div class="pdf-col-value aa"></div>
								<div class="pdf-col-value bb"></div>
								<div class="pdf-col-value cc"></div>
							</div>
						</template>

					</div>

					<div class="pdf-header"><span class="left">Insurance Information</span> <span class="right"></span></div>
					<div class="pdf-row a">
						<div class="pdf-col-name">Top Three Payers:</div>
						<div class="pdf-col-value"></div>
						<div class="pdf-col-name">Percentage MCR/MCD:</div>
						<div class="pdf-col-value"></div>
					</div>
					<div class="pdf-row a">
						<div class="pdf-col-name">Est Top 3 Procedures:</div>
						<div class="pdf-col-value">{{ selectedLead.procedure1 }}</div>
						<div class="pdf-col-value">{{ selectedLead.procedure2 }}</div>
						<div class="pdf-col-value">{{ selectedLead.procedure3 }}</div>
					</div>
					<div class="pdf-row a">
						<div class="pdf-col-name">Est Volume (Procedures):</div>
						<div class="pdf-col-value"></div>
						<div class="pdf-col-value"></div>
						<div class="pdf-col-value"></div>
					</div>
					<div class="pdf-row">
						<div class="pdf-col-name b-row__a">
							Auth Customer Signature:
						</div>
						<div class="pdf-col-value b-row__a">
							<span class="sig-name">{{ selectedLead.infoSignature.signatureName }}</span>
						</div>
						<div class="pdf-col-name b-row__b">
							Name/Title:
						</div>
						<div class="pdf-col-value b-row__b">
							{{ selectedLead.infoSignature.signatureName }}
						</div>
						<div class="pdf-col-name b-row__c">
							Date
						</div>
						<div class="pdf-col-value b-row__c">
							{{ selectedLead.infoSignature.dateFormatted }}
						</div>
					</div>
				</div>


				<div class="pdf-bottom-line"></div>
				<div class="pdf-bottom-line__text">for Office Use Only</div>
				<div class="pdf-bottom-line__extra">
					<div class="left">
						<div class="item"><span>CA/CC</span><span>________________________</span></div>
						<div class="item"><span>TS</span><span>________________________</span></div>
					</div>
					<div class="right">
						<div class="item"><span>CRM</span>
							<v-icon class="fal fa-square"/>
						</div>
						<div class="item"><span>INTR</span>
							<v-icon class="fal fa-square"/>
						</div>
					</div>

				</div>


			</div>
		</div>
		<v-bottom-sheet v-model="deleteModal">
			<v-sheet
					class="text-center"
					height="200px"
			>
				<div class="py-3">
					Please confirm you want to delete this lead:
				</div>
				<v-btn
						class="mt-6"
						color="primary"
						@click="deleteSelectedLead"
				>
					Yes, Delete it!
				</v-btn>
				<v-btn
						class="mt-6"
						text
						color="red"
						@click="deleteModal = !deleteModal"
				>
					Cancel
				</v-btn>

			</v-sheet>
		</v-bottom-sheet>
	</div>
</template>

<script>
import { analytics, db } from '@/helpers/firebase'
import html2pdf from 'html2pdf.js'
// import html2canvas from "html2canvas";
// import { jsPDF } from "jspdf";

export default {
	name: 'PrivateLeads',
	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'EMS Bio Leads',
		meta: [
			{
				name: 'description',
				content: 'Manage Leads'
			},
		],
		// all titles will be injected into this template
		titleTemplate: '%s'
	},
	props: {},
	components: {},
	data() {
		return {
			leads: {},
			companies: {},
			selectedLead: null,
			deleteModal: false, // should we delete the selected lead?
			isError: {}, // setup on create to watch all formData properties
			checks: {
				mon: false,
				tue: false,
				wed: false,
				thu: false,
				fri: false,
				sat: false,
				sun: false,
			},
		};
	},
	created() {
		db.collection("leads")
				.get()
				.then(querySnapshot => {
					querySnapshot.forEach(doc => {
						// doc.data() is never undefined for query doc snapshots
						this.$set(this.leads, doc.id, doc.data());
					});
				})
				.catch(function(error) {
					console.log("Error getting documents: ", error);
				});

		db.collection("companies")
				.get()
				.then(querySnapshot => {
					querySnapshot.forEach(doc => {
						// doc.data() is never undefined for query doc snapshots
						this.$set(this.companies, doc.id, doc.data());
					});
				})
				.catch(function(error) {
					console.log("Error getting companies: ", error);
				});
	},
	computed: {
		sortedLeads() {
			return Object.keys(this.leads).map(lead => {
				return this.leads[lead];
			}).sort((lead1, lead2) => {
				return new Date(lead1.leadStarted) < new Date(lead2.leadStarted) ? 1 : -1;
			})
		},
		states() {
			return [
				'Alabama', 'Alaska', 'American Samoa', 'Arizona',
				'Arkansas', 'California', 'Colorado', 'Connecticut',
				'Delaware', 'District of Columbia', 'Federated States of Micronesia',
				'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho',
				'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
				'Louisiana', 'Maine', 'Marshall Islands', 'Maryland',
				'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
				'Missouri', 'Montana', 'Nebraska', 'Nevada',
				'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
				'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio',
				'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico',
				'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee',
				'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia',
				'Washington', 'West Virginia', 'Wisconsin', 'Wyoming',
			]
		},
		days() {
			return [
				{
					text: 'Monday',
					value: 'mon',
				},
				{
					text: 'Tuesday',
					value: 'tue',
				},
				{
					text: 'Wednesday',
					value: 'wed',
				},
				{
					text: 'Thursday',
					value: 'thu',
				},
				{
					text: 'Friday',
					value: 'fri',
				},
				{
					text: 'Saturday',
					value: 'sat',
				},
				{
					text: 'Sunday',
					value: 'sun',
				},
			];
		},
	},
	methods: {
		createNewCompany() {
			// this should only be called if there isn't a company for that lead ID...
			const copy = JSON.parse(JSON.stringify(this.selectedLead));
			copy.companyID = copy.leadID;
			delete copy.leadID;

			const date = new Date();
			copy.created = date.toISOString();

			db.collection('companies')
					.doc(copy.companyID)
					.set(copy)
					.then(() => {
						this.$set(this.companies, copy.companyID, copy);
						analytics.logEvent('company_created');
					});
		},
		deleteSelectedLead() {
			db.collection("leads")
					.doc(this.selectedLead.leadID)
					.delete()
					.then(() => {
						this.$delete(this.leads, this.selectedLead.leadID);
						this.selectedLead = null;
						this.deleteModal = false;
					})
					.catch(error => {
						alert('There was a problem deleting, please open your console and contact Rustin');
						console.error(error);
					});

		},
		downloadPDF() {

			// html2canvas(this.$refs.leadsDetails, {
			// 	scale: 1,
			// }).then(canvas => {
			// 	document.body.appendChild(canvas);
			// 	const imgdata = canvas.toDataURL('image/png');
			// 	const doc = new jsPDF('landscape');
			// 	doc.addImage(imgdata, 'PNG', 10, 10);
			// 	doc.save(this.selectedLead.practiceName ? this.selectedLead.practiceName.replace('.', ' ').trim() : 'emsBioDoc' + '.pdf');
			// 	document.body.removeChild(canvas)
			// });


			html2pdf().set({
				html2canvas: { scale: 2, },
				jsPDF: { orientation: 'landscape', }
			}).from(this.$refs.leadsDetails).save(this.selectedLead.practiceName ? this.selectedLead.practiceName.replace('.', ' ').trim() : 'emsBioDoc');
		},
	},
};
</script>

<style lang="scss" scoped>
.pdf-logo {
	max-width: 280px;
	width: 280px;
}

.pdf-logo2 {
	max-width: 270px;
	width: 270px;
}

.leads {
	display: flex;
}

.leads-details {
	display: flex;
	flex-direction: column;
	width: 100%;

	.btn {
		width: 200px;
		margin-top: 1rem;
		margin-left: 1rem;
		margin-bottom: 1rem;
	}

	.tools {
		display: flex;
		justify-content: space-between;
	}
}

.scrolling {
	overflow: auto;
	flex: 1;
	padding: 1rem;
	max-width: 1200px;

	&::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 7px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(0, 0, 0, .5);
		box-shadow: 0 0 1px rgba(255, 255, 255, .5);
	}
}

.pdf-logo.col-3, .pdf-logo2.col-3 {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 25%;
	padding: 12px;
}

.pdf-form {
	.header {
		display: flex;
		justify-content: space-between;
	}
}

.pdf-header {
	background: rgb(251, 235, 82);
	display: flex;
	align-items: center;
	padding-left: 2px;
	padding-right: 55px;
	font-weight: bold;
	border: 1px solid black;
	justify-content: space-between;
}

.pdf-row {
	min-height: 26px;
	display: flex;
	width: 100%;

	.sig-name {
		font-size: 25px;
		font-family: cursive;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&.a, &.b {
		.pdf-col-name {
			width: 205px;
			min-width: 205px;
		}
	}

	.big {
		width: 100%;

		.split {
			display: flex;
		}

		.pdf-col-name {
			width: 150px;
			min-width: 150px;
		}
	}
}

.b-row__a {
	&.pdf-col-name {
		min-width: 205px;
		max-width: 205px;
	}

	&.pdf-col-value {
		flex: 1;
	}

}

.b-row__b {
	&.pdf-col-name {
		min-width: 100px;
		max-width: 100px;
	}

	&.pdf-col-value {
		flex: 1;
	}
}

.b-row__c {
	&.pdf-col-name {
		min-width: 60px;
		max-width: 60px;
	}

	&.pdf-col-value {
		flex: 1;
		max-width: 180px;
	}
}

.section-c {
	.pdf-col-name {
		display: flex;
		justify-content: center;
	}

	.aa {
		max-width: 60%;
		min-width: 60%;
	}

	.bb {
		max-width: 25%;
		min-width: 25%;
	}

	.cc {
		max-width: 15%;
		min-width: 15%;
	}
}

.logo-img {
	max-width: 100%;
}

.pdf {
	&-form {
		border: 1px solid black;
	}

	&-bottom-line {
		border-bottom: 1px dotted black;
		margin-top: .2rem;

		&__text {
			font-size: 10px;
		}

		&__extra {
			margin-left: 8rem;
			display: flex;

			.left {
				.item {
					width: 270px;
					display: flex;
					justify-content: space-between;
				}
			}

			.right {
				margin-left: 4rem;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.item {
					display: flex;
					justify-content: center;
					align-items: center;

					span {
						margin-right: 1rem;
					}
				}
			}
		}
	}

	&-title {
		font-size: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
	}

	&-col {
		&-name {
			background: rgb(242, 242, 242);
			border: 1px solid lightgray;
			padding: 0 5px;
			display: flex;
			align-items: center;
		}

		&-value {
			border: 1px solid lightgray;
			padding: 0 5px;
			flex: 1;
			display: flex;
			align-items: center;
		}
	}
}


.section2__left {
	flex: 1;
}

.section2__right {
	width: 200px;

	.pdf-col-name {
		width: 100px;
	}

	.pdf-col-value {
		max-width: 100px;
		min-width: 100px;
	}
}

.leads-list {
	min-width: 300px;
	max-width: 300px;
	max-height: calc(100vh - 72px);
	overflow: auto;

	&__item {
		cursor: pointer;

		&:hover {
			background: lightgray;
		}

		&--active {
			background: #d3d3d39e;
		}
	}

	.practice-name {
		margin-left: 1rem;
		font-size: 18px;
	}

	.start-time {
		font-size: 12px;
		margin-left: 1rem;
	}
}

.section-header {
	font-size: 20px;
	margin-bottom: 1rem;

	&:not(:first-child) {
		margin-top: 2rem;
	}
}

.horizontal {
	display: flex;
	align-items: center;

	.v-input:not(:first-child) {
		margin-left: 1rem;
	}

	&__checkbox {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid lightgray;
		padding: .5rem 0;

		.hours {
			font-weight: bold;
		}
	}
}
</style>
